import React from "react";
import { Link } from "react-router-dom";
import { StyleLayout } from "./style";

const OrderTotalBox = ({ data }) => {
    return (
        <StyleLayout>
            <div className="container-fluid py-5">
                <div className="container pt-5 pb-3">
                    <div className="text-center mb-3 pb-3">
                        <h6
                            className="text-primary text-uppercase"
                            style={{ letterSpacing: "5px" }}
                        >
                            Master Admin Template
                        </h6>
                        <h1>Total Mail Count</h1>
                    </div>
                    <div className="row">
                        {data?.map((item, index) => {
                            return (
                                <div className="col-lg-4 col-md-6 mb-4" key={index}>
                                    <div className="card" style={{ width: "18rem" }}>
                                        <div className="card-body">
                                            <h5 className="card-title">{item?.domain ? item?.domain : ""}</h5>
                                            <h6 className="card-subtitle mb-2">
                                                Standard Mail Count - {item?.mailCountData?.unread_standard_mail_count ? item?.mailCountData?.unread_standard_mail_count : 0}
                                            </h6>

                                            <h6 className="card-subtitle mb-2">
                                                Priority Mail Count - {item?.mailCountData?.unread_priority_mail_count ? item?.mailCountData?.unread_priority_mail_count : 0}
                                            </h6>

                                            <h6 className="card-subtitle mb-2">
                                                Future Mail Count - {item?.mailCountData?.unread_future_mail_count ? item?.mailCountData?.unread_future_mail_count : 0}
                                            </h6>

                                            <h6 className="card-subtitle mb-2">
                                                Inbox Mail Count - {item?.mailCountData?.unread_mail_count ? item?.mailCountData?.unread_mail_count : 0}
                                            </h6>


                                            <h6 className="card-text">
                                                Total Mail Count - {item?.mailCountData?.total_unread_mail_count ? item?.mailCountData?.total_unread_mail_count : 0}
                                            </h6>
                                            <Link
                                                to={item?.loginUrl}
                                                className="card-link"
                                                target="_blank"
                                            >
                                                Click here
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </StyleLayout>
    );
};

export default OrderTotalBox;
