import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ReactDataTable from "../../../Components/ReactDataTable/ReactDataTable";
import ReactTemplate from "../../../Components/ReactTemplate/ReactTemplate";
import {
  getCaFolderCount,
  getCaUnreadMailCount,
  getEgFolderCount,
  getEvwFolderCount,
  getEvwUnreadMailCount,
  getKrFolderCount,
  getMalFolderCount,
  getSgFolderCount,
  getSgUnreadMailCount,
  getTurkeyFolderCount,
  getUsFolderCount,
  getUsUnreadMailCount,
  getVnFolderCount,
  getVnUnreadMailCount,
  getPhFolderCount,
  getIndonesiaFolderCount,
  getPhUnreadMailCount,
  getIndonesiaUnreadMailCount,
  getMalUnreadMailCount,
  getIndonesiaEvoaFolderCount
} from "../../../redux/orderSlice";
import { StyledContainer, StyledPageTitle, StyledOrderBlock } from "./style";

const NewOrdersCount = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname || "/";
  const NewOrderscount = useSelector((state) => state?.order?.NewOrderscount);
  const malaysiaFolderCount = useSelector((state) => state?.order?.malaysiaFolderCount);
  const indonesiaFolderCount = useSelector((state) => state?.order?.indonesiaFolderCount);
  const philippinesFolderCount = useSelector((state) => state?.order?.philippinesFolderCount);
  const singaporeFolderCount = useSelector((state) => state?.order?.singaporeFolderCount);
  const indonesiaEvoaFolderCount = useSelector((state) => state?.order?.indonesiaEvoaFolderCount);
  const egyptFolderCount = useSelector((state) => state?.order?.egyptFolderCount);
  const koreaFolderCount = useSelector((state) => state?.order?.koreaFolderCount);

  const estaURL = "https://estaportal.online/admin/my_admin.php";
  const sriLankaURL = "https://srilanka-eta.com/admin/my_admin.php";
  const turkeyURL = "https://evisa-turkey.online/admin/my_admin.php";
  const ukeTAURL = "https://uk-eta.online/my_admin.php";
  const canadaURL = "https://canada-eta.online/admin/my_admin.php";

  const estaMailURL = "https://estaportal.online/admin/imapApiUnreadMailCount.php";
  const srilankaMailURL = "https://srilanka-eta.com/admin/imapApiUnreadMailCount.php";
  const ukeTAMailURL = "https://uk-eta.online/imapApiUnreadMailCount.php";
  const canadaMailURL = "https://canada-eta.online/admin/imapApiUnreadMailCount.php";
  const malaysiaArrivalMailURL = "https://malaysia-arrival-card.online/imapApiUnreadMailCount.php";
  const indonesiaEcdServicesURL = "https://indonesia-ecd.services/imapApiUnreadMailCount.php";
  const philippinesURL = "https://philippines-etd.com/imapApiUnreadMailCount.php";
  const singaporeURL = "https://sg-arrivalcard.online/imapApiUnreadMailCount.php";
  const indoensiaEvoaURL = "https://indonesia-evoa.online/imapApiUnreadMailCount.php";
  const egyptUnreadMailCountURL = "https://egypt-portal.site/imapApiUnreadMailCount.php";
  const koreaUnreadMailCountURL = "https://egypt-portal.site/imapApiUnreadMailCount.php";
  const officeUnreadMailCountURL = "https://sg-arrivalcard.online/imapApiunreadMailCount_for_office.php";

  const TurkeyMailURL =
    "https://evisa-turkey.online/admin/imapApiUnreadMailCount.php";

  const [estaFolderCount, setEstaFolderCount] = useState([]);
  const [sriLankaFolderCount, setSriLankaFolderCount] = useState([]);
  const [uKeTAFolderCount, setUKeTAFolderCount] = useState([]);

  const [turkeyFolderCount, setTurkeyFolderCount] = useState([0]);
  const [canadaFolderCount, setCanadaFolderCount] = useState([]);
  const [malaysiaArrivalCount, setMalaysiaArrivalCount] = useState([]);
  const [indonesiaCount, setIndonesiaCount] = useState([]);
  const [philippinesCount, setPhilippinesCount] = useState([]);
  const [singaporeCount, setSingaporeCount] = useState([]);
  const [indonesiaEvoaCount, setIndonesiaEvoaCount] = useState([]);
  const [egyptCount, setEgyptCount] = useState([]);
  const [koreaFolderCounts, setKoreaFolderCount] = useState([]);
  const [officeMailCounts, setOfficeMailFolderCount] = useState([]);

  let FinalData = [];
  useEffect(() => {
    const fetchDataInSequence = async () => {
      await dispatch(getIndonesiaEvoaFolderCount());
      // await dispatch(getUsFolderCount());
      await dispatch(getSgFolderCount());
      await dispatch(getVnFolderCount());
      await dispatch(getKrFolderCount());
      await dispatch(getMalFolderCount());
      await dispatch(getPhFolderCount());
      await dispatch(getIndonesiaFolderCount());
      await dispatch(getEgFolderCount())
      await dispatch(getVnUnreadMailCount());
      await dispatch(getUsUnreadMailCount());
      await dispatch(getPhUnreadMailCount());
      // await dispatch(getEvwFolderCount());
      // await dispatch(getCaFolderCount());
      // await dispatch(getMalUnreadMailCount());
      // await dispatch(getEvwUnreadMailCount());
      // await dispatch(getIndonesiaUnreadMailCount());
      // await dispatch(getCaUnreadMailCount());
      // await dispatch(getSgUnreadMailCount());

    };
    fetchDataInSequence();
    document.title = "New Orders Count | Master Admin Template";
  }, [pathname]);

  useEffect(() => {
    let config = {
      method: "get",
      url: estaURL,
    };
    axios
      .request(config)
      .then((response) => {
        const data = response.data;
        let estaData = {
          count: data?.standard_order_count,
          domain: "Esta Site",
          email: "inquiries@estaportal.online",
          loginUrl: "https://estaportal.online/admin",
          priorityOrdersCount: data?.priority_order_count,
        };
        axios
          .get(estaMailURL)
          .then((response) => {
            estaData.mailCount = response?.data?.unread_mail_count;
            setEstaFolderCount([estaData]);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    let config = {
      method: "get",
      url: sriLankaURL,
    };
    axios
      .request(config)
      .then((response) => {
        if (response) {
          const data = response.data;
          let sriLankaData = {
            count: data?.standard_order_count,
            domain: "Sri Lanka Site",
            email: "inquiries@srilanka-eta.com",
            loginUrl: "https://srilanka-eta.com/admin",
            priorityOrdersCount: data?.priority_order_count,
          };
          axios
            .get(srilankaMailURL)
            .then((response) => {
              sriLankaData.mailCount = response?.data?.unread_mail_count;
              setSriLankaFolderCount([sriLankaData]);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);


  useEffect(() => {
    let config = {
      method: "get",
      url: ukeTAURL,
    };
    axios
      .request(config)
      .then((response) => {
        const data = response.data;
        let ukeTAData = {
          count: data?.standard_order_count,
          domain: "UK eTA Site",
          email: "inquiries@uk-eta.online",
          loginUrl: "https://admin-uk.online/",
          priorityOrdersCount: data?.priority_order_count,
        };
        axios
          .get(ukeTAMailURL)
          .then((response) => {
            ukeTAData.mailCount = response?.data?.unread_mail_count;
            setUKeTAFolderCount([ukeTAData]);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    let config = {
      method: "get",
      url: canadaURL,
    };
    axios
      .request(config)
      .then((response) => {
        const data = response.data;
        let canadaData = {
          count: data?.standard_order_count,
          domain: "Canada Site",
          email: "inquiries@canada-eta.online",
          loginUrl: "https://canada-eta.online/admin",
          priorityOrdersCount: data?.priority_order_count,
        };
        axios
          .get(canadaMailURL)
          .then((response) => {

            canadaData.mailCount = response?.data?.unread_mail_count;
            setCanadaFolderCount([canadaData]);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {

    let malaysiaData = {
      count: malaysiaFolderCount?.count,
      domain: "Malaysia Arrival Card Site",
      email: "inquiries@malaysia-arrival-card.online",
      loginUrl: "https://admin-malaysia-arrival.com/",
      priorityOrdersCount: malaysiaFolderCount?.priorityOrdersCount,
    };
    axios
      .get(malaysiaArrivalMailURL)
      .then((response) => {
        malaysiaData.mailCount = response?.data?.unread_mail_count;
        setMalaysiaArrivalCount([malaysiaData]);
      })
      .catch((error) => {
        console.log(error);
      });

  }, [malaysiaFolderCount]);

  useEffect(() => {
    let indonesiaData = {
      count: indonesiaFolderCount?.count,
      domain: "Indonesia ECD Site",
      email: "inquiries@indonesia-ecd.services",
      loginUrl: "https://admin-ind.online/",
      priorityOrdersCount: indonesiaFolderCount?.priorityOrdersCount,
    };
    axios
      .get(indonesiaEcdServicesURL)
      .then((response) => {
        indonesiaData.mailCount = response?.data?.unread_mail_count;
        setIndonesiaCount([indonesiaData]);
      })
      .catch((error) => {
        console.log(error);
      });

  }, [indonesiaFolderCount]);

  useEffect(() => {
    let philippinesData = {
      count: philippinesFolderCount?.count,
      domain: "Philippines Site",
      email: "inquiries@philippines-etd.com",
      loginUrl: "https://admin-ph.online/",
      priorityOrdersCount: philippinesFolderCount?.priorityOrdersCount,
    };
    axios
      .get(philippinesURL)
      .then((response) => {
        philippinesData.mailCount = response?.data?.unread_mail_count;
        setPhilippinesCount([philippinesData]);
      })
      .catch((error) => {
        console.log(error);
      });

  }, [philippinesFolderCount]);

  useEffect(() => {
    let singaporeData = {
      count: singaporeFolderCount?.count,
      domain: "Singapore Site",
      email: "inquiries@sg-arrivalcard.online",
      loginUrl: "https://sg-arrivalcard.online/admin",
      priorityOrdersCount: singaporeFolderCount?.priorityOrdersCount,
    };
    axios
      .get(singaporeURL)
      .then((response) => {
        singaporeData.mailCount = response?.data?.unread_mail_count;
        setSingaporeCount([singaporeData]);
      })
      .catch((error) => {
        console.log(error);
      });

  }, [singaporeFolderCount]);

  useEffect(() => {
    let indonesiaEvoaData = {
      count: indonesiaEvoaFolderCount?.count,
      domain: "Indonesia Evoa Site",
      email: "inquiries@indonesia-evoa.online",
      loginUrl: "https://admin-evoa.online/",
      priorityOrdersCount: indonesiaEvoaFolderCount?.priorityOrdersCount,
    };
    axios
      .get(indoensiaEvoaURL)
      .then((response) => {
        indonesiaEvoaData.mailCount = response?.data?.unread_mail_count;
        setIndonesiaEvoaCount([indonesiaEvoaData]);
      })
      .catch((error) => {
        console.log(error);
      });

  }, [indonesiaEvoaFolderCount]);

  useEffect(() => {
    let egyptData = {
      count: egyptFolderCount?.count,
      domain: "Egypt Site",
      email: "inquiries@egypt-portal.site",
      loginUrl: "https://admin-eg.online/",
      priorityOrdersCount: egyptFolderCount?.priorityOrdersCount,
    };
    axios
      .get(egyptUnreadMailCountURL)
      .then((response) => {
        egyptData.mailCount = response?.data?.unread_mail_count;
        setEgyptCount([egyptData]);
      })
      .catch((error) => {
        console.log(error);
      });

  }, [egyptFolderCount]);


  // malaysia arrival card for unread mail count
  // need to update it once the admin portal completed then we will fetch the data from 
  // node api 
  // useEffect(() => {
  //   const malaysiaArrivalData = {
  //     count: 0,
  //     domain: "Malaysia Arrival Card Site",
  //     email: "inquiries@malaysia-arrival-card.online",
  //     loginUrl: "https://admin-malaysia-arrival.com/",
  //     priorityOrdersCount: 0
  //   };
  //   axios
  //     .get(malaysiaArrivalMailURL)
  //     .then((response) => {

  //       malaysiaArrivalData.mailCount = response?.data?.unread_mail_count;
  //       setMalaysiaArrivalCount([malaysiaArrivalData]);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });

  // }, []);

  // korea portal site
  useEffect(() => {
    let koreaData = {
      count: koreaFolderCount?.count,
      domain: "Korea Site",
      email: "inquiries@korea-portal.online",
      loginUrl: "https://admin-sk.online/",
      priorityOrdersCount: koreaFolderCount?.priorityOrdersCount,
    };
    axios
      .get(koreaUnreadMailCountURL)
      .then((response) => {
        koreaData.mailCount = response?.data?.unread_mail_count;
        setKoreaFolderCount([koreaData]);
      })
      .catch((error) => {
        console.log(error);
      });

  }, [koreaFolderCount]);


  // office unread mail count
  useEffect(() => {
    let officeMailData = {
      domain: "Office Send Inbox",
      email: "inquiries@korea-portal.online",
    };
    axios
      .get(officeUnreadMailCountURL)
      .then((response) => {
        officeMailData.mailCount = response?.data?.unread_mail_count;
        setOfficeMailFolderCount([officeMailData]);
      })
      .catch((error) => {
        console.log(error);
      });

  }, []);



  // useEffect(() => {
  //   let config = {
  //     method: "get",
  //     url: ukeTAURL,
  //   };
  //   axios
  //     .request(config)
  //     .then((response) => {
  //       const data = response.data;
  //       let ukeTAData = {
  //         count: data?.standard_order_count,
  //         domain: "Korea Site",
  //         email: "inquiries@korea-portal.online",
  //         loginUrl: "https://korea-portal.online/",
  //         priorityOrdersCount: data?.priority_order_count,
  //       };
  //       axios
  //         .get(ukeTAMailURL)
  //         .then((response) => {
  //           ukeTAData.mailCount = response?.data?.unread_mail_count;
  //           setUKeTAFolderCount([ukeTAData]);
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //         });
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);

  // korea portal end




  // useEffect(() => {
  //   let config = {
  //     method: "get",
  //     url: turkeyURL,
  //   };
  //   axios
  //     .request(config)
  //     .then((response) => {
  //       const data = response.data;
  //       let turkeyData = {
  //         count: data?.standard_order_count,
  //         domain: "Turkey Site",
  //         email: "inquiries@evisa-turkey.online",
  //         loginUrl: "https://evisa-turkey.online/admin",
  //       };

  //       axios
  //         .get(TurkeyMailURL)
  //         .then((response) => {
  //           if (response) {

  //             turkeyData.mailCount = response?.data?.unread_mail_count;
  //             setTurkeyFolderCount([turkeyData]);
  //           }
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //         });
  //       setTurkeyFolderCount(turkeyData);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);

  setTimeout(function () {
    window.location.reload();
  }, 60000 * 5);

  const handleClick = () => {
    window.location.reload();
  }

  // const turkeyFolderCountArray = Array.from(turkeyFolderCount);
  if (
    NewOrderscount?.length > 0 ||
    estaFolderCount?.length > 0 ||
    sriLankaFolderCount?.length > 0 ||
    uKeTAFolderCount?.length > 0 ||
    // turkeyFolderCountArray?.length > 0 &&
    canadaFolderCount?.length > 0 ||
    malaysiaArrivalCount?.length > 0 ||
    indonesiaCount?.length > 0 ||
    philippinesCount?.length > 0 ||
    singaporeCount?.length > 0 ||
    indonesiaEvoaCount?.length > 0 ||
    egyptCount?.length > 0 ||
    koreaFolderCounts?.length > 0
  ) {
    FinalData = [...NewOrderscount, ...estaFolderCount, ...sriLankaFolderCount, ...uKeTAFolderCount, ...canadaFolderCount, ...malaysiaArrivalCount, ...indonesiaCount, ...philippinesCount, ...singaporeCount, ...indonesiaEvoaCount, ...egyptCount, ...koreaFolderCounts,...officeMailCounts];
  }

  const tableData = FinalData;

  return (
    <StyledContainer>
      <StyledPageTitle>
        <h1>New Orders Count</h1>
        <button type="button" className="btn btn-success" onClick={handleClick}>Refresh</button>
      </StyledPageTitle>
      <form>
        <StyledOrderBlock>
          <div className="table-block table-responsive">
            <ReactTemplate data={tableData} />
          </div>
        </StyledOrderBlock>
      </form>
    </StyledContainer>
  );
};

export default NewOrdersCount;
